<template>
<div>

  <NavigationBar />

  <div class="main">
    <div><h1>Welcome to Email Blaster 3</h1></div>
    <!-- <div class="cta-btn"><a target="_blank" :href="signupUrl">Blast!</a></div> -->
    <div><a href="/assets/manifest" download>Manifest</a></div>
  </div>

  <Footer></Footer>

</div>
</template>

<script>
import NavigationBar from '../common/NavigationBar.vue';
import Footer from '../common/Footer.vue';

export default {
  name: "Home",
  components: {
    NavigationBar,
    Footer,
  },
  computed: {
    signupUrl(){
      return `https://login.microsoftonline.com/common/adminconsent?client_id=${CLIENT_ID}`;
      // https://graph.microsoft.com/Mail.Send https://graph.microsoft.com/User.Read https://graph.microsoft.com/User.Read.All https://graph.microsoft.com/User.ReadBasic.All
      // https://login.microsoftonline.com/{tenant}/v2.0/adminconsent
      //   ?client_id=6731de76-14a6-49ae-97bc-6eba6914391e
      // login.microsoftonline.com/organizations/oauth2/v2.0/authorize
    }
  }
};
</script>

<style scoped>

.main {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: monospace;
  color: #333;
}
.box>*:first-child {
    align-self: stretch;
}

.cta-btn{
    background-color: #0ab451;
    margin-top: 20px;
    padding: 10px 16px;
}
.cta-btn > a{
    font-family: monospace;
    color: #fff;
    text-decoration: none;
}
</style>
