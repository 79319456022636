<template>
    <nav class="navbar">
      <div class="content">
        <div class="logo">
          <!-- Your logo here -->
          <img src="/assets/pages/logo.svg">
        </div>
        <ul class="nav-links">
          <li><a href="">Pricing</a></li>
          <li><a href="https://blasteremail.com/contact-us/" target="_blank">Contact Us</a></li>
          <li><a href="https://blasteremail.com/privacy/" target="_blank">Privacy</a></li>
        </ul>
        <button class="btn-outlook" @click="addToOutlook">Add to Outlook</button>
      </div>
    </nav>
  </template>
  
  <script>
  export default {
    name: 'NavigationBar',
    methods: {
      addToOutlook() {
        window.open("https://appsource.microsoft.com/en-us/product/office/WA200005112?tab=Overview")
      }
    }
  }
  </script>
  
  <style scoped>
  .navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #0071D1;
    height: 136px;
    padding: 15px 0;
  }
  .content{
    background-color: #fff; 
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .logo img{
    width: 166px;
    padding: 21px 19px;
  }
  
  .nav-links {
    list-style: none;
    display: flex;
    gap: 2rem;
    flex-grow: 2;
    justify-content: right;
    padding-right: 70px;
  }
  /*
  .nav-links li a {
    font-family: "Encode Sans Expanded", sans-serif;
    text-decoration: none;
    color: #000; 
    font-weight: 400;
    font-size: 25px;
    line-height: 31px;
  }
  */
  .nav-links li a {
    font-family: 'Roboto', sans-serif;;
    text-decoration: none;
    color: #000;
    font-weight: 400;
    font-size: 25px;
    line-height: 31px;
  }

  
  .btn-outlook {
    background-color: #0071D1;
    width: 171px;
    height: 55px;
    color: white;
    font-family: "Roboto", sans-serif;
    font-weight: bold;
    font-size: 18px;
    font-style: normal;
    border: none;
    padding: 0.5rem 1rem;
    margin-right: 32px;
    cursor: pointer;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    
  }
  
  .btn-outlook:hover {
    background-color: #005ba1;
  }
  </style>
  