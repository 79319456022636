<!-- PricingCard.vue -->
<template>
      <div class="pricing-card">
          <div class="card-holder">
            <div class="card-under" :style="{ backgroundColor: cardColor }"></div>
            <div class="card-top"></div>
            <div class="plan-title-container">
              <div class="plan-title">{{ title }}</div>
            </div>
            <div class="plan-content-container">
              <div class="plan-content-title" :style="{ color: cardColor }" >Including</div>
              <div class="plan-content-features">
                <ul>
                  <li v-for="feature in features" :key="feature">{{ feature }}</li>
                </ul>
              </div>
            </div>
            <div class="plan-price-container">
              <div class="plan-price-value" :style="{ color: cardColor }">
                <span class="big-text">${{price}}</span><span class="small-text">/Month</span>
              </div>
              <div class="plan-price-button">
                <div class="paypal-btn" v-show="showPayPalBtn" :id="paypalContainer"></div>
                <button @click="onBtnClick()" v-show="!showPayPalBtn" :style="{ backgroundColor: cardColor }">Let's go</button>
              </div>
            </div>
          </div>
      </div>

  </template>
  
  <script>
  import axios from 'axios';
  export default {
    name: 'PricingCard',
    props: {
      paypalEnabled: Boolean,
      paypalContainer: String,
      paypalPlan: String,
      plan: String,
      title: String,
      features: Array,
      price: String,
      cardColor: String,
      buttonColor: String,
      clientId: String
    },

    data() {
      return {
          showPayPalBtn: false,
      };
    },

    methods: {
      onBtnClick(){
          if (this.paypalEnabled) {
            this.showPayPalBtn = true
          } else {
            window.open('https://outlook.office.com/mail/')
          }
          axios.get(`/payment/btn/${this.plan}`);
        },

      initPayPal(){
        const that = this
        const payPalOptions = {
          'plan_id': this.paypalPlan,
          'custom_id': this.clientId,
        };
        paypal.Buttons({
          createSubscription(data, actions) {
              return actions.subscription.create(payPalOptions);
          },
          onApprove(data) {
            that.$emit("onApprove", that.plan)
            // console.log(data)
            that.showPayPalBtn = false
            // window.open('https://outlook.office.com/mail/')
          }
        }).render(`#${this.paypalContainer}`)
      }
    },
    mounted(){
      console.log(this.paypal)
      if (this.paypalPlan) {
        this.initPayPal();
      }
    }
  }
  </script>
  
<style>


:root {
  --big-parent-width: 360px;
  --big-parent-height: 111px;

  --med-parent-height: 80px;
  --med-parent-width: 300px
}



.pricing-card {
  font-family: Arial, sans-serif;
  width: 450px;
  height: 900px;
  position: relative;
  text-align: center;
}

.card-holder{
  position: relative;
  width: var(--big-parent-width);
  height: 650px;
  padding: 0;
}
.card-under{
  position: absolute;
  left: 0;
  top: 0;
  width: var(--big-parent-width);
  height: 111px;
  /* background-color: #E32D84; */
  border-radius: 0px 45px 0px 0px;
  padding: 0;
  margin: 0;
  z-index: 10;
}
.card-under::after {
  content: '';
  position: absolute;
  top: auto;
  left: 50%;
  width: 250px;
  height: 222px;
  background-color: inherit;
  border-radius: 100px;
  transform: translateX(-50%);
  border-bottom: 1px solid #000000;
  z-index: 9;
}
.card-top{
  position: absolute;
  top: 111px;
  left: 0;
  width: 359px;
  height: 730px;
  background: #FFFFFF;
  border: 1px solid #000000;
  border-bottom-right-radius: 70px;
  border-bottom-left-radius: 70px;
  box-shadow: 3px 3px 30px rgba(0, 0, 0, 0.75);
  padding: 0;
  margin: 0;
  z-index: 8;
}

.plan-title-container{
  position: absolute;
  width: var(--big-parent-width);
  height: 222px;
  left: 0px;
  top: 0px;
  display: flex;
  justify-content: center;  
  z-index: 20;

}
.plan-title{
  font-family: 'Fenix';
  font-style: normal;
  font-weight: 400;
  font-size: 70px;
  line-height: 78px;
  display: flex;
  align-items: center;
  color: #FFFFFF;
}


.plan-content-container{
  position: absolute;
  width: var(--big-parent-width);
  left: 0px;
  top: 222px;
  z-index: 20;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  padding-top: 40px;
}
.plan-content-title{
  font-family: 'Fenix';
  font-size: 60px;
  line-height: 78px;
  display: flex;
  align-items: center;
}
.plan-content-container ul{
  font-family: 'Fenix';
  font-style: normal;
  font-weight: 400;
  font-size: 23px;
  line-height: 26px;
  color: #000000;
}

.plan-content-container ul {
  padding-left: 0;
  font-size: 23px;
  line-height: 1.6;
}

.plan-content-container ul li {
  margin-bottom: 10px;
  text-align: left;
}

.plan-content-container ul li:last-child {
  margin-bottom: 0;
}


.plan-price-container{
  position: absolute;
  width: var(--big-parent-width);
  height: 200px;
  left: 0px;
  top: 622px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  z-index: 20;
}
.plan-price-value .big-text{
  font-family: 'Fenix';
  font-size: 60px;
}
.plan-price-value .small-text{
  font-family: 'Fenix';
  font-size: 30px;
}

.plan-price-button{
  margin-top: 5px;
}
.plan-price-button button{
  width: 301px;
  height: 67px;
  background: #E32D84;
  border-radius: 45px;
  font-size: 30px;
  line-height: 45px;
  color: #FFFFFF;
  border: 0;
  cursor: pointer;
  margin-top: 10px;
}

@media (max-height: 1150px) {
    .pricing-card{
      width: 350px;
      height: 900px;
    }
    .card-holder{
      width: var(--med-parent-width);
      height: 325px;
    }
    .card-under{
      width: var(--med-parent-width);
      height: var(--med-parent-height);
    }
    .card-under::after{
      width: 200px;
      height: 150px;
    }

    .plan-title-container{
      width: var(--med-parent-width);
      height: 150px;
    }

    .card-top{
      width: var(--med-parent-width);
      top: var(--med-parent-height);
      height: 550px;
    }

    .plan-title{
      font-size: 50px;
    }

    .plan-content-title{
      font-size: 40px;
      line-height: 39px;
    }
    .plan-content-container{
      width: var(--med-parent-width);
      top: calc(var(--med-parent-height)*2 + 10px);
      padding-top: 0;
      height: 310px;
    }
    .plan-content-container ul{
      font-size: 20px;
    }
    .plan-price-container{
      width: var(--med-parent-width);
      height: 100px;
      top: calc(var(--med-parent-height)*2 + 300px);
    }
    .plan-price-value .big-text{
      font-size: 45px;
    }
    .plan-price-button button{
      width: 200px;
      height:45px;
      font-size: 20px;
      line-height: 23px;
    }
  }

</style>
