<template>
    <footer class="footer">
      <div class="grid-container">
        <div class="grid-item">
          <div class="text-container">
            <h4>Support</h4>
            <ul>
              <li><a href="#">Get support</a></li>
            </ul>
          </div>
        </div>
        <div class="grid-item">
          <div class="text-container">
            <h4>Categories</h4>
            <ul>
              <li><a href="#">Email Marketing in Outlook 365</a></li>
              <li><a href="#">Email Newsletters in Outlook 365</a></li>
              <li><a href="#">Cold Email Master Guide</a></li>
            </ul>
          </div>
        </div>
        <div class="grid-item">
          <div class="text-container">
            <h4>Social</h4>
            <div class="social-icons">
              <a href="#"><img src="/assets/icons/fb-icon.png" alt="Facebook"></a>
              <a href="#"><img src="/assets/icons/x-icon.png" alt="Twitter"></a>
              <a href="#"><img src="/assets/icons/youtube-icon.png" alt="Youtube"></a>
            </div>
          </div>
        </div>
        <div class="grid-item">
          <div class="text-container">
            <h4>Important</h4>
            <ul>
              <li><a href="#">What makes BlasterEmail better</a></li>
              <li><a href="#">Sending limits</a></li>
              <li><a href="#">Need Microsoft 365</a></li>
            </ul>
          </div>
        </div>
        <div class="grid-item">
          <div class="text-container">
            <h4>Basic</h4>
            <ul>
              <li><a href="#">System Status</a></li>
              <li><a href="#">Terms of Use and Anti-Spam Policy</a></li>
              <li><a href="#">Privacy Policy</a></li>
              <li><a href="#">Report Vulnerability</a></li>
            </ul>
          </div>
        </div>

      </div>
    </footer>
  </template>
  
  <script>
  export default {
    name: 'Footer',
    // Any reactive data or methods would go here
  }
  </script>
  
  <style scoped>
  /* .footer {
    background-color: #2c3e50;
    color: white;
    display: flex;
    justify-content: space-around;
    padding: 20px;
    font-size: 14px;
  } */

.footer {
  background-color: #0071D1;
  padding: 20px; /* Add some padding around the grid */
  min-height: 100vh;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(3, 0.3fr); /* Creates 3 columns, each 500px wide */
  grid-template-rows: repeat(3, auto); /* Creates rows to accommodate content, can adjust height as needed */
  gap: 50px; /* Space between grid items */
  justify-content: center; /* Center the grid horizontally */
  align-content: center; /* Center the grid vertically */
}
.text-container {
  width: 80%;
  text-align: left;
}
.grid-item {
  display: flex;
  flex-direction: column;
  /* justify-content: center; Center content horizontally */
  /* align-items: center; Center content vertically */
  /* height: 500px; Set height of cells, you can adjust this as needed */
  background-color: #0071D1; /* This will make the items have the same background color */
  color: #ffffff; /* Set text color for contrast */
  border: 1px solid #ffffff; /* Optional: Adds a border to each grid item for clarity */
  /* text-align: left; */
  align-items: center;
  border: 0
}
  
  .footer h4 {
    align-items: start;
    font-family: "Roboto", sans-serif;
    font-size: 28px;
    font-weight: bold;
    margin-bottom: 30px;
    /* align-self: flex-start; */
  }
  
  .footer ul {
    list-style: none;
    padding: 0;
    /* align-self: flex-start; */
  }
  
  .footer a {
    font-family: "Roboto", sans-serif;
    font-size: 24px;
    text-decoration: none;
    color: white;
    display: block;
    margin-bottom: 5px;
    /* align-self: flex-start; */
  }
  
  .footer a:hover {
    text-decoration: underline;
  }
  
  .social-icons {
    display: flex;
    flex-direction: row;
  }
  .social-icons img {
    width: 24px;
    height: 24px;
    margin-right: 10px;
  }
  </style>
  