import { createApp } from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import VueSimpleAlert from "vue3-simple-alert-next";

const app = createApp(App);
app.use(router)
app.use(store);
app.use(VueSimpleAlert);
app.mount('#app');
