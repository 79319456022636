<template>
    <div>

      <NavigationBar />

      <div class="main-page">
        <div class="main-title">Hello {{ user.email }} You on <span class="plan">{{ user.plan }}</span> Plan</div>
        <!-- <div class="secondary-title">Choose one of the plans and let's go</div> -->
        <div class="pricing-container">
          <!-- P-6D644497VG725702HMXWFD7A  bussinnes account plan -->
          <PricingCard
            title="Free"
            :features="['Integration with Office365', '10 Recipient per send', 'Use basic templates', '2 costume templates', 'Time scheduling emails']"
            price="0"
            cardColor="#E32D84"
            buttonColor="#E32D84"
            plan="free"
          />
          <PricingCard
            title="Pro"
            :features="['All in free plan', 'Blaster promotion remove', 'Use tracing & analytics', '100 Recipient per send', '5 costume templates']"
            price="9.9"
            cardColor="#00B0F0"
            buttonColor="#00B0F0"
            :paypalEnabled=allowPurchasePro
            paypalContainer="paypal-button-container-pro"
            paypalPlan="P-9VU628035R0507517MY53BQI"
            plan="pro"
            :clientId="clientId"
            @onApprove="onApprove"
          />
          <PricingCard
            title="Master"
            :features="['All in pro plan', '500 Recipient per send', 'Download reports', 'Premium support', '20 costume templates']"
            price="35"
            cardColor="#37DDB2"
            buttonColor="#37DDB2"
            :paypalEnabled=allowPurchaseMaster
            paypalContainer="paypal-button-container-master"
            paypalPlan="P-64253708SW058034UMY53BQQ"
            plan="master"
            :clientId="clientId"
            @onApprove="onApprove"
          />
        </div>
      </div>

      <!-- <Footer></Footer> -->
      
    </div>
</template>
  
<script>
import axios from 'axios';
import _ from 'lodash';
import PricingCard from '../common/PricingCard.vue';
import NavigationBar from '../common/NavigationBar.vue';

export default {
  name: 'Pricing',
  components: {
    PricingCard,
    NavigationBar,
  },
  data() {
    return {
      clientId: '',
      user: {},
    };
  },
  async created() {
    this.clientId = this.$route.query.c;
    if (!this.clientId){
      this.$alert("We couldn't identify you");
      window.open('https://outlook.office.com/mail/')
    }
    await this.getUser()

    if (_.isEmpty(this.user)){
      this.$alert("We couldn't identify you");
      return
    }
  },

  methods: {
    async getUser() {
      try {
        const res = await axios.get(`/user/id/${this.clientId}`);
        this.user = res.data.user
      } catch(err) {
        console.error(err);
      }
    },

    allowPurchase(){
      return !_.isEmpty(this.user) && this.user.plan != 'free'
    },

    onApprove(plan){
      this.$alert("Thank you for your purchase");
      this.user.plan = plan;
    }
  },

  computed: {
    allowPurchasePro(){
      return !_.isEmpty(this.user) && !['pro', 'master'].includes(this.user.plan)
    },
    allowPurchaseMaster(){
      return !_.isEmpty(this.user) && this.user.plan != 'master'
    }
  }
}
</script>

<style>
body{
  margin: 0;
  padding: 0;
}
.main-page {
  text-align: center;
}

.pricing-container {
  display: flex;
  justify-content: center;

}
.main-title{
/* .secondary-title{ */
  font-family: 'Encode Sans Expanded';
  font-style: normal;
  font-weight: 400;
  font-size: 46px;
  line-height: 58px;
  display: flex;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 40px;
}
.main-title .plan{
  padding: 0 0.3em;
}
.main-title .plan::first-letter{
  text-transform: capitalize;
}

@media (max-height: 1150px) {
  .main-title{
      font-size: 36px;
  }
}
/* .secondary-title{
  margin-bottom: 90px;
} */


</style>
