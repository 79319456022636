import {getField, updateField} from "vuex-map-fields";
import { getCurrentInstance } from 'vue';

const state = {
  exampleVar: 10
}
const getters = {
  getField,
}

const mutations = {
  updateField,
}

const actions = {
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
